export const header = {
    menu: [
        {
            link: 'howitworks',
            title: 'How It Works'
        },
        {
            link: 'offerings',
            title: 'Offerings'
        },
        {
            link: 'testimonials',
            title: 'Testimonials'
        }
    ],
    btn_title: 'Consult Online Now',
}

export const landing = {
    title: 'Grassetto',
    sub_title: 'Interiors | Renovation | Home Decor',
    description: 'We create the design in a collaborative environment that enables us to deliver the client\'s ultimate vision. Our designs are current yet possess a timeless style generating broad market appeal. Our reputation for outstanding services along with our innovative design style creates an extremely successful partnership with our clients.',
    btn_title: 'Book Online Consultation',
}

export const howitworks = {
    title: 'How it works',
    content: [
        {
            image: '',
            title_consolidated: 'Consult',
            title: 'Consult our designer-Free Consultation',
            sub_title: 'Talk to our designer and get personalized designs and quotations for your dream home.',
            btn_title: 'Consult Now',
        },
        {
            image: '',
            title_consolidated: 'Book',
            title: 'Book with us',
            sub_title: 'Once you are happy with the consultation and design proposal, book with Grassetto paying 10%.'
        },
        {
            image: '',
            title_consolidated: 'Place Order',
            title: 'Place the order',
            sub_title: 'Finalize the design and we will commence the initiation of your project. With the next installment of 40% and we will be able to turn your design into reality.'
        },
        {
            image: '',
            title_consolidated: 'Execution',
            title: 'Execution and Installation',
            sub_title: 'Pay 30% before the execution, orders will be delivered on-site and we will initiate the installation.'
        },
        {
            image: '',
            title_consolidated: 'Move In!',
            title: 'One more step to go',
            sub_title: 'And for the final installment, the rest 20% will be collected and you are ready to move in. Ta-da, you\'re dream home is ready.'
        }
    ]
}

export const offerings = {
    title: 'Offerings',
    content: [
        {
            carousel_images: ['offerings_1.png', 'offerings_1_1.jpg', 'offerings_1_2.png'],
            image: 'offerings_1.png',
            title: 'Complete home interiors',
            sub_title: 'We provide end-to-end solutions and services for your full home- Kitchen, Living room, Bedroom, and more. We also offer home renovation services.'
        },
        {
            image: 'offerings_2.png',
            title: 'Modular Solutions',
            sub_title: 'Tailor-made designs, size, shape, and finishes are offered to create a modular kitchen, wardrobes, storage units, and more.',
            sub_offerings: [
                {
                    carousel_images: ['offerings_2_1.jpeg', 'offerings_2_1_1.png', 'offerings_2_1_2.png', 'offerings_2_1_3.jpg', 'offerings_2_1_4.png'],
                    image: 'offerings_2_1.jpeg',
                    title: 'Modular Kitchen',
                    sub_title: 'Personalised Kitchen cabinets with an endless range of finishes, materials, appliances, hardware, and countertop\'s to choose from accommodating our customer requirements.',
                },
                {
                    carousel_images: ['offerings_2_2.jpeg', 'offerings_2_2_1.png', 'offerings_2_2_2.png', 'offerings_2_2_3.png', 'offerings_2_2_4.png'],
                    image: 'offerings_2_2.jpeg',
                    title: 'Modular Wardrobe',
                    sub_title: 'Offered in a range of floor-ceiling, sliding and swing door wardrobe solutions with various finishes to choose from, ensuring abundant storage space.',
                },
                {
                    carousel_images: ['offerings_2_3.png', 'offerings_2_3_1.png', 'offerings_2_3_2.png', 'offerings_2_3_3.png', 'offerings_2_3_4.png', 'offerings_2_3_5.png', 'offerings_2_3_6.png', 'offerings_2_3_7.jpeg'],
                    image: 'offerings_2_3.png',
                    title: 'Modular Storage Units',
                    sub_title: 'Shoe racks, T.V Unit, Crockery unit, Study table, storage units, vanity units, and more are offered.',
                }
            ]
        },
        {
            carousel_images: ['offerings_3.png', 'offerings_3_1.png', 'offerings_3_2.jpeg', 'offerings_3_3.png', 'offerings_3_4.jpg', 'offerings_3_5.jpg', 'offerings_3_6.png'],
            image: 'offerings_3.png',
            title: 'Custom Products',
            sub_title: 'Are you looking for anything exclusive and personalized? We will design and make it especially for you, crafted by different people from different places with different perspectives.'
        },
        {
            carousel_images: ['offerings_4.png', 'offerings_4_1.jpg', 'offerings_4_2.jpg'],
            image: 'offerings_4.png',
            title: 'Civil work and Installation',
            sub_title: 'Starting from flooring, false ceiling, painting, and everything else required for you home.'
        },
        {
            carousel_images: ['offerings_5.png', 'offerings_5_1.png', 'offerings_5_2.png', 'offerings_5_3.png', 'offerings_5_4.png', 'offerings_5_5.png', 'offerings_5_6.png', 'offerings_5_7.png', 'offerings_5_8.png', 'offerings_5_9.png', 'offerings_5_10.png'],
            image: 'offerings_5.png',
            title: 'Commercial Spaces',
            sub_title: 'Grassetto Studio offers bespoke commercial interior design solutions for our clients looking to evoke their brand image through functional designed spaces. We offer commercial spaces design includes office interior design, retail interior design, Hospitality, and Educational spaces.'
        }
    ]
}

export const testimonials = {
    title: 'Testimonials',
    sub_title: 'What our clients say about us.',
    content: [
        {
            title: 'Shardamba Nagappa',
            sub_title: '"The inputs from the team on different options availability vs quality, costing surely helped us decide the best for our home  great attitude to progress the project in the right direction is much appreciated."'
        },
        {
            title: 'Kiran',
            sub_title: '"The team has good expertise and they were very helpful and professional and the execution took place in the given timeline."'
        },
        {
            title: 'Pavani Koppuluri',
            sub_title: '"Grassetto understood our requirement very well and exactly designed the way we expected."'
        }
    ]
}

export const footer = {
    title: 'Grassetto Studio',
}