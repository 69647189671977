import { Form, Input, Modal, Select } from 'antd'
import React from 'react'

const { Option } = Select

const LeadCollectionForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm()

    const prefixSelector = (
        <Form.Item
            name="prefix"
            noStyle
        >
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="91">+91</Option>
            </Select>
        </Form.Item>
    )

    return (
        <Modal
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields()
                        onCreate(values)
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info)
                    })
            }}
            okText="Book Consultation"
            title="Talk to a designer"
            visible={visible}
        >
            <Form
                form={form}
                initialValues={{
                    prefix: '91',
                }}
                layout="vertical"
                name="form_in_modal"
                scrollToFirstError
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            message: 'Please input your name!',
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            message: 'Please input your email!',
                            required: true
                        },
                    ]}
                >
                    <Input type="email" />
                </Form.Item>
                <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[
                        {
                            message: 'Please input your phone number!',
                            required: true,
                        },
                    ]}
                >
                    <Input
                        addonBefore={prefixSelector}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default LeadCollectionForm