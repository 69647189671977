import { Carousel, Row } from 'antd'
import React from 'react'
import './ImageCarousel.sass'

const ImageCarousel = ({ images }) => {
    console.log(images)
    return (
        <Carousel autoplay dotPosition="top">
            {
                images.map((image, index) => (
                    <Row justify="center" align="bottom" key={index}>
                        <img alt="blah" src={`${process.env.PUBLIC_URL}/assets/images/${image}`} />
                    </Row>
                ))
            }
        </Carousel>
    )
}

export default ImageCarousel