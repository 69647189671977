import { FacebookFilled, InstagramFilled, LinkedinFilled, MailFilled, PhoneFilled, TwitterSquareFilled } from '@ant-design/icons'
import { Button, Col, Layout, message, Row, Space } from 'antd'
import axios from 'axios'
import React from 'react'
import { Link } from "react-scroll"
import { footer, header } from './data'
import './DefaultLayout.sass'
import Home from './Home'
import LeadCollectionForm from './LeadCollectionForm'
import logo from './logo.png'

const { Header, Content, Footer } = Layout

class DefaultLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    onCreate = values => {
        axios.post('https://rovzvzqox9.execute-api.us-west-2.amazonaws.com/dev', values)
            .then(response => {
                message.success('Thank you for showing interest. We will reach out to you shorty.')
                this.setState({
                    visible: false
                })
            })
            .catch(() => {
                message.error('Something went wrong. Try again later')
            })
    }

    render() {
        const { visible } = this.state
        return (
            <Layout>
                <Header>
                    <img className="logo" src={logo} alt="logo" height="55" />
                    {/* <span >{header.logo}</span> */}
                    <Space size="large" id="menu" direction="horizontal">
                        {
                            header.menu.map((item, index) => (
                                <Link
                                    activeClass="active"
                                    to={item.link}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    key={`link-${index}`}
                                >
                                    {item.title}
                                </Link>
                            ))
                        }
                        <Button
                            onClick={() => {
                                this.setState({
                                    visible: true
                                })
                            }}
                            shape="round"
                            type="primary"
                        >
                            {header.btn_title.toUpperCase()}
                        </Button>
                    </Space>
                </Header>
                <Content className="site-layout">
                    <Home />
                </Content>
                <Footer>
                    <Row
                        align="middle"
                        //gutter={[48, 48]}
                        justify="center"
                    >
                        <Col
                            md={8}
                            style={{ textAlign: 'center' }}
                            xs={24}
                        >
                            <Space align="middle">
                                <Button
                                    href="https://www.linkedin.com/company/grassetto-studio-s/about/"
                                    icon={<LinkedinFilled />}
                                    size="large"
                                    type="link"
                                />
                                <Button
                                    href="https://www.facebook.com/Grassetto-Studio-101339888281434/"
                                    icon={<FacebookFilled />}
                                    size="large"
                                    type="link"
                                />
                                <Button
                                    href="https://www.instagram.com/grassetto_studio/"
                                    icon={<InstagramFilled />}
                                    size="large"
                                    type="link"
                                />
                                <Button
                                    href="https://twitter.com/grassettos"
                                    icon={<TwitterSquareFilled />}
                                    size="large"
                                    type="link"
                                />
                            </Space>
                        </Col>
                        <Col
                            md={8}
                            style={{ textAlign: 'center' }}
                            xs={24}
                        >
                            ©2020 {footer.title}
                        </Col>
                        <Col
                            md={8}
                            style={{ textAlign: 'center' }}
                            xs={24}
                        >
                            <Space
                                align="center"
                                id="contactUs"
                            >
                                <Space align="center" size="small">
                                    <PhoneFilled />
                                    <Button
                                        style={{ paddingLeft: 0 }}
                                        type="link"
                                        href="tel:+918660505609"
                                    >
                                        +918660505609
                                    </Button>
                                </Space>
                                <Space align="center" size="small">
                                    <MailFilled />
                                    <Button
                                        style={{ paddingLeft: 0 }}
                                        type="link"
                                        href="mailto:hello@grassettostudio.in"
                                    >
                                        hello@grassettostudio.in
                                    </Button>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Footer>
                <LeadCollectionForm
                    visible={visible}
                    onCreate={this.onCreate}
                    onCancel={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                />
            </Layout>
        )
    }
}

export default DefaultLayout