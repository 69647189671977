import { Button, Card, Carousel, Col, Divider, message, Row, Space, Steps, Typography, Modal } from 'antd'
import axios from 'axios'
import React, { Component } from 'react'
import Slide from 'react-reveal/Slide'
import { howitworks, landing, offerings, testimonials } from './data'
import './Home.sass'
import landing_image from './landing.png'
import LeadCollectionForm from './LeadCollectionForm'
import ImageCarousel from './ImageCarousel'
import _ from 'lodash'

const { Meta } = Card
const { Step } = Steps
const { Paragraph, Title } = Typography

class Home extends Component {
	constructor(props) {
		super(props)
		this.state = {
			current: 0,
			visible: false,
			modalVisible: false,
			images: []
		}
	}

	onChange = current => {
		console.log('onChange:', current);
		this.setState({ current });
	}

	onCreate = values => {
		axios.post('https://rovzvzqox9.execute-api.us-west-2.amazonaws.com/dev', values)
			.then(response => {
				message.success('Thank you for showing interest. We will reach out to you shorty.')
				this.setState({
					visible: false
				})
			})
			.catch(() => {
				message.error('Something went wrong. Try again later')
			})
	}

	setModalVisible = (modalVisible, index) => {
		if (index === -1) {
			this.setState({
				modalVisible: modalVisible,
				images: []
			})
		}
		else {
			this.setState({
				modalVisible: modalVisible,
				images: index.indexOf('_') > -1 ? offerings.content[index.split("_")[0]].sub_offerings[index.split("_").pop()].carousel_images : offerings.content[index].carousel_images
			})
		}
	}

	render() {
		const { current, visible, images, modalVisible } = this.state
		return (
			<React.Fragment>
				<Row
					align="middle"
					className="container-row"
					justify="center"
					id="landing"
					key="1"
					style={{
						backgroundSize: 'cover'
					}}
				>
					<div className="overlay"></div>
					<Slide bottom>
						<Space
							align="center"
							direction="vertical"
							size="large"
							style={{ textAlign: 'center' }}
						>
							<Title level={1}>{landing.title.toUpperCase()}</Title>
							<Title
								className="para"
								level={2}
							//style={{ textAlign: 'left' }}
							>
								{landing.sub_title}
							</Title>
							<Paragraph className="para">{landing.description}</Paragraph>
							<Button
								onClick={() => {
									this.setState({
										visible: true
									})
								}}
								shape="round"
								size="large"
								type="primary"
							>
								{landing.btn_title.toUpperCase()}
							</Button>
						</Space>
					</Slide>
				</Row>
				<Row
					className="container-row"
					id="howitworks"
					justify="center"
					align="middle"
				>
					<Col
						xs={22}
						style={{ textAlign: 'center' }}
					>
						<Row justify="center" align="middle">
							<Space
								direction="vertical"
								size="large"
								align="center"
								style={{ width: '100%' }}
							>
								<Title level={2}>{howitworks.title.toUpperCase()}</Title>
								<Row justify="center" gutter={[24, 24]}>
									<Col
										className="col-card"
										md={20}
										//style={{ borderRadius: 40, backgroundColor: '#170e64' }}
										style={{ borderRadius: 40 }}
										xs={10}
									>
										<Steps current={current} onChange={this.onChange}>
											{
												howitworks.content.map((item, index) => (
													<Step
														key={`step-${index}`}
														title={item.title_consolidated}
													/>
												))
											}
										</Steps>
									</Col>
									<Col
										md={12}
										xs={14}
									>
										<Row justify="center">
											<Card
												bodyStyle={{ padding: 0 }}
												bordered={false}
												className="card-special"
											>
												{/* <img alt="" src={`${process.env.PUBLIC_URL}/assets/images/hiw_${current + 1}.png`} width="50" /> */}
												<Meta
													title={<Title level={3}>{howitworks.content[current].title}</Title>}
													description={
														<Space direction="vertical">
															{howitworks.content[current].sub_title}
															{!_.isUndefined(howitworks.content[current].btn_title) && <Button
																onClick={() => {
																	this.setState({
																		visible: true
																	})
																}}
																shape="round"
																size="large"
																type="primary"
															>
																{howitworks.content[current].btn_title.toUpperCase()}
															</Button>}
														</Space>
													}
												/>
											</Card>
										</Row>
									</Col>
								</Row>
							</Space>
						</Row>
					</Col>
				</Row>
				<Row
					align="middle"
					className="container-row"
					id="offerings"
					justify="center"
					key="3"
				>
					<Col
						md={16}
						xs={22}
					>
						<Space
							align="middle"
							direction="vertical"
							size="large"
						>

							<Slide bottom><Title level={2} style={{ textAlign: 'center' }}>{offerings.title.toUpperCase()}</Title></Slide>
							{
								offerings.content.map((offering, index, arr) => (
									<React.Fragment key={`offering-${index}`}>
										{
											offering.sub_offerings ?
												<Space
													align="center"
													direction="vertical"
													size="large"
												>
													<Row
														justify="center"
													>
														<Col
															md={12}
															xs={22}
														>
															<Slide bottom>
																<Card
																	bodyStyle={{ padding: 0, textAlign: 'center' }}
																	bordered={false}
																	style={{ background: 'transparent' }}
																>
																	<Meta
																		title={<Title level={3}>{offering.title}</Title>}
																		description={offering.sub_title}
																	/>
																</Card>
															</Slide>
														</Col>
													</Row>
													<Space
														direction="vertical"
														size="large"
													>
														{
															offering.sub_offerings.map((sub_offering, subindex) => (
																<Row
																	align="middle"
																	gutter={[48, 48]}
																	justify="center"
																	key={`sub_offering-${subindex}`}
																>
																	<Col
																		md={{ span: 16, push: subindex % 2 === 0 ? 8 : 0 }}
																		xs={22}
																	>
																		<Slide bottom>
																			<Card
																				bodyStyle={{ padding: 0 }}
																				bordered={false}
																				cover={
																					<img
																						alt="sample"
																						onClick={() => this.setModalVisible(true, `${index}_${subindex}`)}
																						src={`${process.env.PUBLIC_URL}/assets/images/${sub_offering.image}`}
																						style={{ boxShadow: '0 .59rem .98rem rgba(184,173,209,.6)' }}
																					/>
																				}
																				style={{ background: 'transparent' }}
																			/>
																		</Slide>
																	</Col>
																	<Col
																		md={{ span: 8, pull: subindex % 2 === 0 ? 16 : 0 }}
																		xs={22}
																	>
																		<Slide bottom>
																			<Card
																				bodyStyle={{ padding: 0, textAlign: 'center' }}
																				bordered={false}
																				style={{ background: 'transparent' }}
																			>
																				<Meta
																					title={<Title level={3}>{sub_offering.title}</Title>}
																					description={sub_offering.sub_title}
																				/>
																			</Card>
																		</Slide>
																	</Col>
																</Row>
															))
														}
													</Space>
												</Space> :
												<Row
													gutter={[48, 48]}
													justify="center"
													key={`offering-${index}`}
													align="middle"
												>
													<Col
														md={{ span: 16, push: index % 2 !== 0 ? 8 : 0 }}
														xs={22}
													>
														<Slide bottom>
															<Card
																bodyStyle={{ padding: 0 }}
																bordered={false}
																cover={
																	<img
																		alt="sample"
																		onClick={() => this.setModalVisible(true, `${index}`)}
																		src={`${process.env.PUBLIC_URL}/assets/images/${offering.image}`}
																		style={{ boxShadow: '0 .59rem .98rem rgba(184,173,209,.6)' }}
																	/>
																}
																style={{ background: 'transparent' }}
															/>
														</Slide>
													</Col>
													<Col
														md={{ span: 8, pull: index % 2 !== 0 ? 16 : 0 }}
														xs={22}
													>
														<Slide bottom>
															<Card
																bodyStyle={{ padding: 0, textAlign: 'center' }}
																bordered={false}
																style={{ background: 'transparent' }}
															>
																<Meta
																	title={<Title level={3}>{offering.title}</Title>}
																	description={
																		offering.sub_title
																	}
																/>
															</Card>
														</Slide>
													</Col>
												</Row>
										}
										<Row justify="center">
											{arr.length - 1 !== index && <Divider style={{ minWidth: '60%', width: '60%' }} />}
										</Row>
									</React.Fragment>
								))
							}
						</Space>
					</Col>
				</Row>
				<Row
					align="middle"
					className="container-row"
					id="testimonials"
					justify="center"
					key="4"
				>
					<Col
						md={12}
						xs={22}
					>
						<Slide bottom>
							<Title level={2} style={{ textAlign: "center", marginBottom: 24 }}>{testimonials.title.toUpperCase()}</Title>
							<Carousel autoplay>
								{
									testimonials.content.map((testimonial, index) => (
										<Card
											bodyStyle={{ padding: 0, textAlign: 'center' }}
											bordered={false}
											className="card-special"
											key={`testimonial-${index}`}
										>
											<Meta
												title={<Title level={3}>{testimonial.title}</Title>}
												description={testimonial.sub_title}
											/>
										</Card>
									))
								}
							</Carousel>
						</Slide>
					</Col>
				</Row>
				<LeadCollectionForm
					visible={visible}
					onCreate={this.onCreate}
					onCancel={() => {
						this.setState({
							visible: false
						})
					}}
				/>
				<Modal
					bodyStyle={{ width: "90%", margin: '0 auto' }}
					footer={null}
					centered
					visible={modalVisible}
					onCancel={() => this.setModalVisible(false, -1)}
					width="100%"
				>
					<ImageCarousel images={images} />
				</Modal>
			</React.Fragment >
		)
	}
}
export default Home